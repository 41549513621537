import React from 'react';
import About from './components/about/default';
import Contact from './components/contact/default';
import Nav from './components/nav/default';
import Skills from './components/skills/default';
import Websites from './components/websites/default';
import Testimonials from './components/testimonials/default';
import './App.css';

function App() {
  return (
    <div className="Main">
      <Nav />
      <About />
      <Websites />
      <Skills />
      <Testimonials />
      <Contact />
    </div>
  );
}

export default App;
