import React, {useState, useRef} from "react";

export default function Contact() {  
  const formRef = useRef(null)
  const scriptUrl = "https://script.google.com/macros/s/AKfycbyCghftzn6z_-P1oUThSgCDMtAngm242CVljGkJ9-4WT2Morle-Frixjyakq3P4GfuU/exec"
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) =>{
      e.preventDefault()
      setLoading(true)

      fetch(scriptUrl, {
      method: 'POST', 
      body: new FormData(formRef.current),

  }).then(res => {
          console.log("SUCCESSFULLY SUBMITTED")
      })
      .catch(err => console.log(err))
  }
    return (
        <section id="contact" className="text-gray-400 bg-gray-900 body-font relative items-center justify-center">
          <div className="container px-5  py-10 mx-auto flex sm:flex-nowrap flex-wrap">
          {!loading ? (
            <form ref={formRef} onSubmit={handleSubmit} name="save-google-sheet" id="save-google-sheet"
                className="flex flex-col mx-auto w-2/3 md:py-8 mt-8 md:mt-0 justify-start relative">
                <h2 className="text-white sm:text-4xl text-3xl mb-1 font-medium title-font flex items-center justify-center">
                  Contact Me
                </h2>
                <p className="ltext-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto mb-5">
                Hi there! If you would like to get in touch with me and find out more about my services, feel free to fill out the form below. I would love to hear from you! Please provide your name, email address, and a brief message, and I will get back to you as soon as possible.
                </p>
                <div className="relative mb-4">
                  <label htmlFor="name" className="leading-7 text-sm text-gray-400">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
                <div className="relative mb-4">
                  <label htmlFor="email" className="leading-7 text-sm text-gray-400">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
                <div className="relative mb-4">
                  <label
                    htmlFor="message"
                    className="leading-7 text-sm text-gray-400">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  />
                </div>
                <div className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg  items-center justify-center">
                  <input className="mx-auto w-full" type="submit" value={loading ? "Loading..." : "Submit"} /> 
                </div>
              </form> 
           ) : (
              <p className="ltext-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto mb-5 h-24">Thank you for your interest. I will get back to you as soon as possible.</p>  
           )}  
          </div>
        </section>
      );
}