export const websites = [
    {
      title: "Film Dads",
      subtitle: " ",
      description:
        "Personal movie information and review blog using headless WordPress CMS and React with React Routing",
      image: "./images/project-1.jpg",
      link: "http://thefilmdads.com",
    },
    {
      title: "The Next Level Consulting",
      subtitle: " ",
      description:
        "Site for The Next Level Consulting Company using React with React Routing",
      image: "./images/project-2.jpg",
      link: "http://thenext-level.org",
    },
  ];
  
//   export const testimonials = [
//     {
//       quote:
//         "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
//       image: "https://randomuser.me/api/portraits/men/1.jpg",
//       name: "Jesse Hicks",
//       company: "Zoozle",
//     },
//     {
//       quote:
//         "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
//       image: "https://randomuser.me/api/portraits/men/94.jpg",
//       name: "Ruben Alvarez",
//       company: "Dooble.io",
//     },
//   ];
  
  export const skills = [
    "JavaScript",
    "React",
    "Angular",
    "Vue",
    "GraphQL",
    "WordPress Headless CMS",
    "SASS",
    "Adobe Photoshop",
    "Adobe Illustrator",
    "Adobe XD",
    "Figma",
    "Sketch",
  ];
  